import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ChartPages from "../../components/ChartPages/ChartPages";
import { UserCard } from "../../components/UserCard/UserCard";
import { ChartBooks } from "../../components/ChartBooks/ChartBooks";
import { Medals } from "../../components/Medals/Medals";
import { Pie } from "../../components/Pie/Pie";
import { LoadingUserPage } from "../../components/Loading/LoadingUserPage";

import { db } from "../../utils/fire";

import { collection, getDocs } from "firebase/firestore";
import back from "../../img/User/back.jpg";
import "./User.scss";
import { CompareCard } from "../../components/CompareCard/CompareCard";

export const User = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const userCollectionRef = collection(db, "yearStat");

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(userCollectionRef);
      try {
        let result = data.docs.map((doc) => ({
          ...doc.data(),
        }));
        setData(Object.values(result[0]));
      } catch (err) {
        console.log(err);
      }
    };
    getUsers();
  }, []);

  const user = data.filter((item) => item.siteId === id);
  const getGenres = (data) => {
    let sortData = data.filter(
      (item) => item.name !== "Без жанру" && item["жанр"] > 0
    );

    if (sortData.length) {
      let res = sortData.map((item) => [item.name, item["жанр"]]);
      return res;
    } else {
      return 0;
    }
  };

  const getNoGenres = (data) => {
    let sortData = data.filter((item) => item.name === "Без жанру");

    if (sortData[0]["жанр"] > 0) {
      return sortData[0]["жанр"];
    } else {
      return 0;
    }
  };

  const findTotalPages = (pages) => {
    let total = 0;

    if (pages.length) {
      for (let i = 0; i < pages.length; i++) {
        total += pages[i]["сторінки"] || 0;
      }
    }
    return total;
  };

  const getBack = () => {
    navigate("/");
  };

  const books = user[0]?.books2023 - user[0]?.books2022;
  const reviews = user[0]?.reviews2023 - user[0]?.reviews2022;
  const pages2023 = user[0]?.stat2023 ? findTotalPages(user[0]?.stat2023) : 0;
  const pages2022 = user[0]?.stat2022 ? findTotalPages(user[0]?.stat2022) : 0;
  const pages = pages2023 - pages2022;

  return data && user.length ? (
    <div className="user">
      <p className="title_User_Page">Твоя читацька статистика за 2024</p>
      <div className="cards">
        <div>
          <UserCard
            color="#c7e6d0"
            count={user[0].books2023}
            desc="Прочитано книг"
            className="card"
          />
          <UserCard
            color="#f7f7f7"
            count={user[0].reviews2023}
            desc="Відгуки"
            className="card"
          />
        </div>
        <div>
          <UserCard
            color="#a8ccb5"
            count={user[0].readDayNow}
            desc="Читаєш підряд"
            className="card"
          />
          <UserCard
            color="#d3dfeb"
            count={user[0].readDayMax}
            desc="Max дні читання"
            className="card1"
          />
        </div>
      </div>
      <div className="charts">
        <ChartPages
          title="Кількість прочитаних сторінок"
          data={user[0].stat2023}
          xAxis="name"
          yAxis="сторінки"
        />
        <div className="statistics">
          <div className="statistics_chart">
            <p className="title">Статистика книг по місяцях</p>
            <ChartBooks data={user[0].statBooks2023} />
          </div>
          <div className="statistics_pie">
            <p className="title">Жанри твоїх книг</p>
            <Pie
              data={getGenres(user[0].statGenres)}
              noGenres={getNoGenres(user[0].statGenres)}
            />
          </div>
        </div>
      </div>
      {!!pages2022 && (
        <div className="compareBlock">
          <p className="compare__title">цього року ви прочитали на</p>
          <div className="compare">
            <CompareCard count={books} desc="книг" />
            <CompareCard count={reviews} desc="відгуків" />
            <CompareCard count={pages} desc="сторінок" />
          </div>
          <p className="compare__desc">чим минулого року</p>
        </div>
      )}
      <div className="medals">
        <Medals user={user} />
      </div>
      <div className="user_footer">
        <div className="quote">
          <figure>
            <blockquote cite="#місяцьчитання">
              <p>
                Читай ще більше в новому році разом з марафоном #місяцьчитання
              </p>
            </blockquote>
          </figure>
        </div>
        <div className="blockBack">
          <img src={back} alt="" className="img" onClick={getBack} />
        </div>
      </div>
    </div>
  ) : (
    <LoadingUserPage />
  );
};
